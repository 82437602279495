@import url("https://fonts.googleapis.com/css?family=Tangerine");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;0,700;1,800");
@import url("https://fonts.googleapis.com/css?family=Pirata+One");

.infinite-viewer {
  height: 100%;
  width: 100%;
}

.target {
  min-width: 200px;
  min-height: 200px;
  max-width: 400px;
  max-height: 400px;
}

.moveable-line {
  position: absolute;
  width: 0px;
  height: 0px;
  background: #000 !important;
  border: 1px dashed var(--chakra-colors-primary-100);
  transform-origin: 0px 0.5px;
}

.moveable-control {
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 0% !important;
  border: 2px solid var(--chakra-colors-primary-100) !important;
  box-sizing: border-box;
  background: var(--chakra-colors-primary-600) !important;
  margin-top: -7px;
  margin-left: -7px;
  z-index: 10;
}
.react-q5 {
  position: absolute;
  bottom: 2px;
  left: -5px;
  pointer-events: none;
}

.css-lntgos {
  background: var(--chakra-colors-salmon) !important;
  color: var(--chakra-colors-primary-900) !important;
}

.css-3b6enb {
  background: var(--chakra-colors-primary-100) !important;
  color: var(--chakra-colors-primary-900) !important;
}

textarea::placeholder {
  color: var(--chakra-colors-primary-500);
  font-weight: 400;
}

@keyframes flame-twirl {
  0%,
  22%,
  49%,
  62%,
  81%,
  100% {
    border-radius: 2px 14px 8px 8px / 20px 20px 8px 8px;
  }
  14%,
  32%,
  56%,
  70%,
  89% {
    border-radius: 14px 2px 8px 8px / 20px 20px 8px 8px;
  }
}
@keyframes glow {
  0%,
  30%,
  60%,
  80%,
  100% {
    box-shadow: 0 0 30px 2px var(--chakra-colors-flame);
  }
  20%,
  50%,
  70% {
    box-shadow: 0 0 32px 0 var(--chakra-colors-flame);
  }
}

/* The fade in  */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 0.1s;
  -moz-animation-duration: 0.1s;
  animation-duration: 0.1s;
}

@keyframes shine {
  50% {
    background: var(--chakra-colors-salmonBright);
    transform: scale(1.1) translate(50%,-50%);    
  }
}

@keyframes shake {
  5%,
  45% {
    transform: translate3d(-1px, 0, 0);
  }

  10%,
  40% {
    transform: translate3d(2px, 0, 0);
  }

  15%,
  25%,
  35% {
    transform: translate3d(-4px, 0, 0);
  }

  20%,
  30% {
    transform: translate3d(4px, 0, 0);
  }
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #00000035;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #00000035;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--chakra-colors-primary-400);
  border-radius: 20px;
  margin: 4px;
}
